<template>
  <section class="filters-container">
    <b-row>
      <b-col cols="6">
        <label for="healthPlan">Convênio</label>
        <HealthPlanFilter :selectHealthPlan="handleSelectedHealthPlans" />
      </b-col>

      <b-col cols="2">
        <label for="batch_number">Nº do lote</label>
        <b-form-input
          id="batch_number"
          v-model="filters.batchNumber"
          placeholder="Pesquisar"
          debounce="500"
        />
      </b-col>

      <b-col v-if="statusOptions.length > 1" cols="2">
        <label for="batch_status">Situação</label>
        <multiselect
          id="batch_status"
          class="with-border"
          placeholder="Selecionar"
          v-model="filters.status"
          :show-labels="false"
          :options="statusOptions"
          :multiple="true"
          :searchable="true"
          :close-on-select="true"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template slot="selection" slot-scope="{ values }">
            <span>
              <Ellipsis>{{ values.join(', ') }}</Ellipsis>
            </span>
          </template>
        </multiselect>
      </b-col>

      <b-col :cols="statusOptions.length > 1 ? 2 : 4">
        <label for="batch_type">Tipo do Lote</label>
        <multiselect
          id="batch_type"
          class="with-border"
          track-by="value"
          label="label"
          placeholder="Selecionar"
          v-model="filters.type"
          :show-labels="false"
          :options="guideOptions"
          @select="changeSelectedType"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
        </multiselect>
      </b-col>
    
      <b-col cols="2">
        <label for="period">Período</label>
        <Period id="period" @select="handlePeriod" />
      </b-col>

      <b-col cols="2">
        <label for="period">Fatura</label>
        <b-form-input
          id="invoice"
          v-model="filters.invoiceNumber"
          placeholder="Pesquisar"
          debounce="300"
        />
      </b-col>

      <b-col cols="4">
        <label for="nf">Nota fiscal</label>
        <InputSearch
          id="nf"
          placeholder="Buscar NF"
          v-model="filters.nf"
          debounce="300"
        />
      </b-col>

      <b-col cols="4">
        <label for="protocol_number">Protocolo</label>
        <InputSearch
          id="protocol_number"
          placeholder="Buscar protocolo"
          v-model="filters.protocolNumber"
          debounce="300"
        />
      </b-col>
    </b-row>
  </section>
</template>
<script>
export default {
  components: {
    HealthPlanFilter: () => import('./HealthPlanFilter'),
    InputSearch: () => import('@/components/General/InputSearch'),
    Period: () => import('@/components/General/Periods'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
  },
  props: {
    statusOptions: {
      type: Array,
      default: () => []
    },
    showStatus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      filters: {
        status: '',
        healthPlan: null,
        batchNumber: null,
        invoiceNumber: null,
        nf: null,
        protocolNumber: null,
        type: null,
        period: {
          start: null,
          end: null
        }
      },
      guideOptions: [
        { label: 'Guia de consulta', value: 'TissGuideConsultation' },
        { label: 'Guia SP/SADT', value: 'TissGuideSpSadt' },
        { label: 'Guia de Honorários', value: 'TissGuideFee' },
        // { label: 'Guia de resumo de internação', value: 'TissGuideHospitalizationSummary' },
      ],
    }
  },
  methods: {
    handlePeriod(period) {
      this.filters.period.start = period.start
      this.filters.period.end = period.end
    },
    handleSelectedHealthPlans(newValue) {
      this.filters.healthPlan = newValue?.value ?? null
    },
    changeSelectedType(newValue) {
      this.filters.type = newValue
    },
    changeSelectedStatus(newValue) {
      this.filters.status = newValue
    },
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('change', newValue)
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
.filters-container {
  margin: 20px 0;

  label {
    font-weight: 700;
  }
}
.selection-join {
  padding: 0 16px;
}
</style>
